<template>
  <b-modal id="config-modal" 
    :title="form?.id ? 'Atualizar configuração' : 'Nova configuração'"
    @hidden="closeConfig"
    centered 
    size="lg"
  >
    <b-container>
      <b-row>
        <b-col cols="4">
          <label class="label">
            Tipo de despesa
          </label>
          <CheckBoxSelect
            :value="form.expenseTypes"
            :options="expenseTypesOptions"
            :label="expenseTypesOptions.join(', ')"
            @onChange="value => form.expenseTypes = value"
          />
        </b-col>
        <b-col cols="8">
          <label class="label">
            Tabela de preço
          </label>
          <multiselect
            class="form-control p-0"
            v-model="form.table"
            label="label"
            track-by="value"
            :disabled="loadingTables"
            :options="tableOptions"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Selecionar"
            openDirection="bottom"
            :showLabels="false" 
          >
            <template slot="noOptions">Digite para pesquisar uma tabela</template>
            <template slot="noResult">Nenhuma tabela encontrada</template>
          </multiselect>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <label  class="label">
            Formação de preço
          </label>
          <multiselect
            class="form-control p-0"
            v-model="form.priceFormation"
            :options="['Preço Fábrica (PF)', 'Preço Máximo do Consumidor (PMC)']"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Selecionar"
            openDirection="bottom"
            :showLabels="false" 
          />
        </b-col>
        <b-col>
          <label for="name" class="label">
            Inflator/Deflator
          </label> 
          <b-input-group append="%">
            <b-input 
              v-model="form.inflatorDeflator"
              type="number"
              class="input-append"
            />
          </b-input-group>
        </b-col>
        <b-col>
          <label for="date" class="label">
            Data vigência inicial
          </label>
          <date-picker
            id="date"
            v-model="form.initialDate"
            format="DD/MM/YYYY"
            placeholder="DD/MM/AAAA"
            :clearable="false"
            class="full"
            autocomplete="off"
          />
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div>
        <b-button variant="outline-danger" @click="closeConfig()">
          Cancelar
        </b-button>
        <b-button 
          v-if="validForm"
          variant="primary" 
          class="ml-2"
          @click="saveConfig()"
        >
          Salvar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import api from '@/modules/tiss/matMed/api'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'ConfigModal',
  components: {
    CheckBoxSelect: () => import('@/components/CheckboxSelect.vue'),
  },
  props: {
    clinicHealthPlanId: String,
    tissMatMedConfig: Object,
    clearOnHide: Boolean,
    expenseTypesOptions: {
      type: Array,
      default: () => ['Materiais', 'Medicamentos'],
    }  
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      loadingTables: true,
      form: {
        expenseTypes: [],
        table: null,
        priceFormation: null,
        inflatorDeflator: null,
        initialDate: null,
      },
      tableOptions: [
        { label: 'Tabela Simpro', value: 'simpro'},
        { label: 'Tabela Brasindice', value: 'brasindice'},
      ]
    }
  },
  computed: {
    validForm() {
      return (
        this.form.expenseTypes.length 
        && this.form.table 
        && this.form.priceFormation 
        && this.form.inflatorDeflator 
        && this.form.initialDate
      )
    }
  },
  created() {
    this.getImportedMaterialMedicines()
  },
  methods: {
    setDefaultForm() {
      this.form = {
        expenseTypes: [],
        table: null,
        priceFormation: null,
        inflatorDeflator: null,
        initialDate: null,
      }
    },
    async getImportedMaterialMedicines() {
      try {
        this.loadingTables = true

        const res = await api.getImportedMaterialMedicines(this.clinic.id)
        this.tableOptions = res.data.map(
          table => ({ 
            label: `Tabela ${table.table_type}`, 
            value: table.table_type
          })
        )

        this.loadingTables = false
      } catch (error) {
        this.$toast.error('Não foi possível carregar as tabelas, contate o suporte se o erro persistir')
      }
    },
    async saveConfig() {
      if (!this.validForm) {
        this.$toast.error('Preencha todos os campos para continuar')
        return
      }
      const isLoading = this.$loading.show()
      try {
        this.$bvModal.hide('config-modal')

        const payload = {
          table: this.form.table.value,
          price_formation: this.form.priceFormation,
          inflator_deflator: this.form.inflatorDeflator,
          expense_types: this.form.expenseTypes,
          clinic_health_plan_id: this.clinicHealthPlanId,
          initial_date: this.moment(this.form.initialDate).format('YYYY-MM-DD'),
        }

        const { data } = this.form.id
          ? await api.updateMatMedConfig(this.form.id, payload)
          : await api.createMatMedConfig(payload)
          
        this.$emit('onSave', data)
        this.$router.push(`/materiais-medicamentos/configuracao/${this.clinicHealthPlanId}/${data.id}`)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    closeConfig() {
      if(this.clearOnHide){
        this.setDefaultForm()
      }
      this.$bvModal.hide('config-modal')
    }
  },
  watch: {
    tissMatMedConfig: {
      handler(newValue) {
        if (!newValue) {
          this.setDefaultForm()
          return
        }

        this.form = {
          id: newValue?.id,
          expenseTypes: newValue.expense_types,
          table: this.tableOptions.find(table => table.value === newValue.table),
          priceFormation: newValue.price_formation,
          inflatorDeflator: newValue.inflator_deflator,
          initialDate: new Date(newValue.initial_date),
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    color: var(--dark-blue, #0C1D59);
    font-size: 16px;
    font-family: Nunito Sans;
    font-weight: 600;
    line-height: 150%;
  }

  .input-group {
    .input-append {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
</style>